/* Global Font Settings */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 18px; /* Default font size */
  line-height: 1.6; /* Improve readability */
  color: #333; /* Default text color */
}
/* Hero Section */
.hero-container {
  background: url('/public/images/Moab3.jpg') no-repeat center center/cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 0;
}

.hero-content {
  color: white;
  z-index: 1;
  position: relative;
  max-width: 80%;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 5rem; 
  font-weight: 500; 
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
}

.hero-content p {
  font-size: 2.2rem; 
  margin-bottom: 8rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.hero-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem; /* Spacing between buttons */
}

.start-now-btn {
  background-color: #007bff; /* Blue button */
  color: white;
  font-size: 1.5rem;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.start-now-btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05);
}

.learn-more-btn {
  background: linear-gradient(to bottom, #f9f9f9, #eaeaea); /* Same gradient as services-intro */
  color: #333; /* Dark text for contrast */
  border: 1px solid #ccc; /* Subtle border */
  font-size: 1.5rem;
  padding: 0.75rem 2rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.learn-more-btn:hover {
  background-color: #eaeaea; /* Slightly darker gradient on hover */
  color: #000; /* Darker text for hover */
  transform: scale(1.05);
}


.company-name {
  font-weight: bold;
  color: #007bff;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.company-name:hover {
  color: #0056b3;
  text-shadow: 0 2px 5px rgba(0, 123, 255, 0.5);
}

/* Button Styles */
button {
  font-size: 1.5rem; 
  padding: 0.75rem 2rem;
  z-index: 1;
  transition: all 0.3s ease;
}

button:hover {
  transform: scale(1.05);
}

button:focus {
  outline: 2px solid #fff;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 0.75rem 2rem;
}
/* Contact Section */
.contact-section {
  background: linear-gradient(
      rgba(0, 0, 0, 0.6), /* Top color: semi-transparent black */
      rgba(0, 0, 0, 0.6)
    ),
    url('/public/images/Snow5.jpg') no-repeat center center/cover;
  position: relative;
  padding: 4rem 2rem;
  text-align: center;
  color: white;
  min-height: 100vh;
}

.contact-title {
  font-size: 3rem; /* Larger font size */
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.contact-tagline {
  font-size: 2rem; /* Larger font size for tagline */
  margin-bottom: 2rem;
}

.contact-buttons {
  display: flex;
  justify-content: center;
  gap: 2rem; /* Increase spacing between buttons */
  margin-bottom: 2rem;
}

.contact-btn {
  background-color: #007bff;
  color: white;
  padding: 1rem 2rem; /* Larger padding for bigger buttons */
  border-radius: 5px;
  font-size: 1.25rem; /* Larger font size for buttons */
  text-decoration: none;
  transition: all 0.3s ease;
}

.contact-btn:hover {
  background-color: #0056b3;
  transform: scale(1.1); /* Slightly larger hover effect */
}

.contact-info {
  font-size: 1.5rem; /* Larger font size for contact info */
  line-height: 1.8;
  color: white; /* Light gray for contrast */
}

/* Services Section */
.service-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem; 
}

.service-card {
  background: linear-gradient(to bottom, #f9f9f9, #eaeaea); 
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-card h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #007bff; 
}

.service-card p {
  font-size: 1rem;
  line-height: 1.5;
  color: black;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); 
}

.services-section .row {
  row-gap: 2rem; 
}

.services-intro {
  text-align: center;
  font-size: 1.25rem; 
  font-weight: 500; 
  color: #333; 
  padding: 1.5rem 2rem;
  background: linear-gradient(to bottom, #f9f9f9, #eaeaea); 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05); 
  margin-bottom: 2rem;
  position: relative;
}

.services-intro span {
  color: #007bff; 
  font-weight: bold;
}

.services-intro::before,
.services-intro::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 3px;
  border-radius: 3px;
}

.services-intro::before {
  top: 0;
}

.services-intro::after {
  bottom: 0;
}

.trusted-section {
  background: linear-gradient(to right, #f9f9f9, #eaeaea);
  padding: 2rem 0;
  text-align: center;
  border-radius: 0;
  margin: 0;
  width: 100%;
  box-shadow: none;
  position: fixed; /* Fix it to the viewport */
  bottom: 0; /* Align it to the bottom of the window */
  left: 0; /* Ensure it starts at the left edge */
  z-index: 1000; /* Ensure it stays above other content */
}

.trusted-title {
  font-size: 2.5rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 1rem;
}

.trusted-text {
  font-size: 1.5rem;
  color: #555;
  line-height: 1.6;
}

.company-name {
  font-weight: bold;
  color: #007bff;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.company-name:hover {
  color: #0056b3;
  text-shadow: 0 2px 5px rgba(0, 123, 255, 0.5);
}
/* Services Section */
.services-section {
  background: url('/public/images/Snow3.jpg') no-repeat center center/cover;
  background-position: center 65%; /* Move image up slightly */
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.services-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for better text readability */
  z-index: 0;
}

.container {
  position: relative;
  z-index: 1; /* Ensure content is above the overlay */
}

.services-title {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Shadow for improved readability */
}

.services-summary {
  font-size: 1.5rem;
  margin: 0 auto 2rem;
  line-height: 1.6;
  max-width: 600px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* Subtle shadow */
}

.services-btn {
  font-size: 1.25rem;
  padding: 0.75rem 2rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.services-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}


.trusted-section {
  background: linear-gradient(to right, #9b9b9b, #d4d4d4);
  padding: 1.5rem 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, .5);
  z-index: 1000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth fade and slide */
}

.trusted-section.visible {
  transform: translateY(0); /* Bring it into view */
  opacity: 1;
}

.trusted-section.hidden {
  transform: translateY(100%); /* Slide it down out of view */
  opacity: 0;
}

.trusted-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 0.5rem;
}

.trusted-text {
  font-size: 1rem;
  color: #000000;
  line-height: 1.6;
}

.company-name {
  font-weight: bold;
  color: #007bff;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.company-name:hover {
  color: #0056b3;
  text-shadow: 0 2px 5px rgba(0, 123, 255, 0.5);
}


/* About/Meet Jeff Section */
.about-section {
  background: url('/public/images/Moab2.jpg') no-repeat;
  background-size: 100%; /* Slight zoom-out */
  background-position: center 30%; /* Move image up slightly */
  color: white;
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
}

.about-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Add a dark overlay for better text visibility */
  z-index: 0;
}

.about-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;

}

.about-title {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.about-text {
  font-size: 2rem;
  line-height: 1.8;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
}

.learn-more-btn {
  background-color: #007bff;
  color: rgb(0, 0, 0);
  font-size: 1.25rem;
  padding: 0.75rem 2rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.learn-more-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Footer */
.footer {
  font-size: 1.25rem; /* Slightly larger footer text */
}


/* Energy Multiplier Section */
.energy-multiplier-section {
  background: url('/public/images/Snow4.jpg') no-repeat center center/cover;
  color: blue;
  padding: 6rem 2rem;
  text-align: center;
  position: relative;
}

.energy-multiplier-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Add a dark overlay for better text visibility */
  z-index: 0;
}

.energy-multiplier-section .container {
  position: relative;
  z-index: 1;
}

.energy-title {
  font-size: 3rem; /* Adjust font size for title */
  margin-bottom: 2rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle text shadow for readability */
}

.energy-multiplier-section p {
  font-size: 1.5rem; /* Adjust font size for paragraph */
  line-height: 1.8;
  margin-bottom: 2rem;
}

.energy-multiplier-section ul {
  list-style: none;
  padding: 0;
}

.energy-multiplier-section li {
  font-size: 1.25rem; /* Adjust font size for list items */
  margin-bottom: 1rem;
  text-align: left; /* Align text for clarity */
}







.energy-multiplier-section {
  background-color: #f7f7f7; /* Light gray */
}

.energy-multiplier-section {
  background: linear-gradient(to bottom, #ffffff, #f0f0f0);
}

.divider {
  width: 100%;
  height: 200px;
  background-color: #ccc; /* Neutral color */
  margin: 20px 0;
}

/* Wrapper for the section layout to include the background effect */
.section-layout-wrapper {
  background: linear-gradient(to bottom right, #e0e5ef, #f9f9f9); /* Subtle gradient */
  padding: 6rem 2rem; /* Space around the card */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Card container */
.section-layout {
  background: white;
  border-radius: 12px; /* Rounded corners for a modern look */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); /* Smooth shadow for depth */
  padding: 2rem;
  max-width: 800px; /* Limit width for readability */
  text-align: center;
}

/* Title styling */
.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

/* Summary styling */
.section-summary {
  font-size: 1.25rem;
  line-height: 1.6;
  color: #666;
}

.about-text2 {
  font-size: 2.5rem;
  line-height: 1.8;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
}
.about-text3 {
  font-size: 1.1rem;
  line-height: 1.8;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
}